const productTypes = [
  'acrylglas',
  'acrylglas-gerahmt',
  'aludibond',
  'aludibond-gerahmt',
  'backlitfolie',
  'bag-tote',
  'bib-baby',
  'blanket-fleece',
  'curtain-shower',
  'cushion-decorative',
  'doormat',
  'forexplatte',
  'forexplatte-gerahmt',
  'fototapete',
  'hahnemuehle',
  'hahnemuehle-alurahmen',
  'hahnemuehle-holzrahmen',
  'hahnemuehle-kunststoffrahmen',
  'hoodie',
  'hoodie-kid',
  'leinwand',
  'leinwand-schattenfuge',
  'mug-ceramic',
  'mug-enamel',
  'notebook',
  'notebook-spiral',
  'phone-cover-iphone',
  'phone-cover-samsung',
  'poster',
  'poster-alurahmen',
  'poster-holzrahmen',
  'poster-kunststoffrahmen',
  'pvcplane',
  'romper-long-sleeve-baby',
  'romper-short-sleeve-baby',
  'set-pillowcase-duvet-cover',
  'sticker',
  'sticker-sheet',
  'sweatshirt',
  'sweatshirt-baby',
  'sweatshirt-kid',
  'towel-bath',
  'towel-beach',
  'towel-tea',
  't-shirt',
  't-shirt-baby',
  't-shirt-fitted-woman',
  't-shirt-kid',
] as const;

type ProductType = typeof productTypes[number];

const productTypeShortNames: Partial<{ [key in ProductType]: string }> = {
  acrylglas: 'agl',
  aludibond: 'ald',
  backlitfolie: 'blf',
  'bag-tote': 'bgt',
  'bib-baby': 'bbb',
  'blanket-fleece': 'bnf',
  'curtain-shower': 'csr',
  'cushion-decorative': 'cud',
  doormat: 'dmt',
  forexplatte: 'frp',
  fototapete: 'ftp',
  hahnemuehle: 'hah',
  hoodie: 'hod',
  'hoodie-kid': 'hok',
  leinwand: 'lei',
  'leinwand-schattenfuge': 'les',
  'mug-ceramic': 'mgc',
  'mug-enamel': 'mge',
  notebook: 'ntb',
  'notebook-spiral': 'nts',
  'phone-cover-iphone': 'pci',
  'phone-cover-samsung': 'pcs',
  poster: 'pst',
  pvcplane: 'pvp',
  'romper-long-sleeve-baby': 'rls',
  'romper-short-sleeve-baby': 'rss',
  'set-pillowcase-duvet-cover': 'spc',
  sticker: 'stk',
  'sticker-sheet': 'sts',
  sweatshirt: 'sws',
  'sweatshirt-baby': 'swb',
  'sweatshirt-kid': 'swk',
  't-shirt': 'tsh',
  't-shirt-baby': 'tsb',
  't-shirt-fitted-woman': 'tsf',
  't-shirt-kid': 'tsk',
  'towel-bath': 'twb',
  'towel-beach': 'twe',
  'towel-tea': 'twt',
} as const;

type MerchSet = Partial<{
  [key in ProductType]: {
    filterFunction: (allSizes: string[]) => string[];
    key: string;
  }[];
}>;

const productSwitchBuybox: ProductType[][][] = [
  [
    [ 'mug-ceramic' ],
    [ 'mug-enamel' ],
  ],
];

const merchSets: MerchSet = {
  'set-pillowcase-duvet-cover': [
    {
      filterFunction: (allSizes: string[]) => allSizes.filter((size) => size.endsWith('-')),
      key: 'pillowcase',
    },
    {
      filterFunction: (allSizes: string[]) => allSizes.filter((size) => size.startsWith('-')),
      key: 'duvetCover',
    },
  ],
} as const;

const frameOptionProducts: ProductType[] = [
 'poster',
 'acrylglas',
 'leinwand',
 'hahnemuehle',
 'aludibond',
 'forexplatte'
];

const unframedWallartProducts: ProductType[] = [
  'acrylglas',
  'aludibond',
  'backlitfolie',
  'forexplatte',
  'hahnemuehle',
  'leinwand',
  'poster',
] as const;

const framedProducts = [
  'acrylglas-gerahmt',
  'aludibond-gerahmt',
  'forexplatte-gerahmt',
  'hahnemuehle-alurahmen',
  'hahnemuehle-holzrahmen',
  'hahnemuehle-kunststoffrahmen',
  'leinwand-schattenfuge',
  'poster-alurahmen',
  'poster-holzrahmen',
  'poster-kunststoffrahmen',
] as const;

type FramedProductType = typeof framedProducts[number];

const wallartProducts = [
  ...unframedWallartProducts,
  ...framedProducts,
] as const;

const wallartPriceApiProducts = [
  ...unframedWallartProducts,
  'echtglas',
  'fototapete',
  'gallerybond',
  'klebefolie',
  'pvcplane',
];

const filterMappingFramed: Record<FramedProductType, ProductType> = {
  'acrylglas-gerahmt': 'acrylglas',
  'aludibond-gerahmt': 'aludibond',
  'forexplatte-gerahmt': 'forexplatte',
  'hahnemuehle-alurahmen': 'hahnemuehle',
  'hahnemuehle-holzrahmen': 'hahnemuehle',
  'hahnemuehle-kunststoffrahmen': 'hahnemuehle',
  'leinwand-schattenfuge': 'leinwand',
  'poster-alurahmen': 'poster',
  'poster-holzrahmen': 'poster',
  'poster-kunststoffrahmen': 'poster',
};

const frameDefaultProduct = (singleType: FramedProductType) => {
  const entry = Object.entries(filterMappingFramed).find(
    ([ key ]) => key === singleType,
  );
  return entry
    ? { [entry[0]]: entry[1] }
    : null;
};

const isFramedProductType = (type: ProductType): type is FramedProductType => type in filterMappingFramed;

const WITHOUT_FRAME = 'without';

export {
  filterMappingFramed,
  frameDefaultProduct,
  framedProducts,
  type FramedProductType,
  frameOptionProducts,
  isFramedProductType,
  merchSets,
  productSwitchBuybox,
  type ProductType,
  productTypeShortNames,
  productTypes,
  unframedWallartProducts,
  wallartPriceApiProducts,
  wallartProducts,
  WITHOUT_FRAME,
};
