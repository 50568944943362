import {
  productSwitchBuybox,
  type ProductType,
} from 'configs';
import { CACHE_KEY_TAGS } from 'configs';
import {
  getDurationInSeconds,
  Logger,
} from 'utils';
import { State } from '@/config/state';
import { UrlParams } from '@/config/url-params';

const useProductData = () => {
  const GqlInstance = useGql();

  type Product = Awaited<ReturnType<typeof GqlProductData>>['productData'];

  type ProductTypeCategory = 'merch' | 'wallart';

  const productData = useState<Product>(State.ProductData, () => []);

  const fetchProductData = async () => {
    if (productData.value.length) {
      return;
    }

    const {
      addToCache,
      value: cachedProductData,
    } = await useDataCache<Product>('product-data');

    if (cachedProductData) {
      productData.value = cachedProductData;
      return;
    }

    try {
      const response = await GqlInstance('ProductData', {});
      productData.value = response.productData;

      addToCache(
        response.productData,
        [
          CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT,
          CACHE_KEY_TAGS.NUXT_MULTI_CACHE_PRODUCT_DATA,
        ],
        getDurationInSeconds({ hours: 1 }),
      );
    } catch (_error) {
      Logger.error('Failed to fetch product data', _error);
    }
  };

  const getProductData = computed(() => productData.value);

  const getVariantsForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.variant?.values ?? null;

  const getMaterialsForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.material?.values ?? null;

  const getAspectRatioSizesForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.aspectRatioSize ?? null;

  const getSizesForProductType = (productType: string, aspectRatios: string[] | undefined) => {
    const aspectRatioSizes = getAspectRatioSizesForProductType(productType);

    const aspectRatioSizesByAspectRatio = aspectRatios && aspectRatios.length
      ? Object.keys(aspectRatioSizes?.values).filter((key) => aspectRatios.includes(aspectRatioSizes?.values[key]))
      : [];

    return aspectRatioSizesByAspectRatio.length
      ? aspectRatioSizesByAspectRatio
      : getProductData.value.find((product) => product.productType === productType)?.size.values;
  };

  const getSizeDefaultForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.size.default as string | Record<string, string> ?? null;

  const getAspectRatiosForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.aspectRatio?.values ?? null;

  const getAspectRatioDefaultForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.aspectRatio?.default as string | Record<string, string> ?? null;

  const getColorsForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.color?.values ?? null;

  const getProductTypeCategoryForProductType = (productType: ProductType) => getProductData.value
    .find((product) => product.productType === productType)?.type as ProductTypeCategory ?? null;

  const getProductsToSwitch = (productType: ProductType, activeProductVariants: string[]): ProductType[] | null => {
    const matchingArray = productSwitchBuybox.find((productGroup) => productGroup.some((arr) => arr.includes(productType)));

    if (matchingArray) {
      const flattenedArray = matchingArray.flat();

      if (flattenedArray.every((item) => activeProductVariants.includes(item))) {
        return flattenedArray;
      }
    }

    return null;
  };

  const getFrameGroupForProductType = (productType: string) => getProductData.value
    .find((product) => product.productType === productType)?.frameGroup?.values ?? [];

  const getFrameGroupDefaults = (productType: string) => {
    const defaults = getProductData.value
      .find((product) => product.productType === productType)?.frameGroup?.defaults ?? [];

    // Based on JQ-3488 this data gets returned as null, though our code expects an empty array,
    // so we need to handle this case and transform it accordingly
    Object.keys(defaults).forEach((key) => {
      defaults[key] ??= [];
    });

    return defaults;
  };

  const getFramesForProductType = (productType: string) => {
    const frameTypeDefaults = getFrameGroupDefaults(productType).frameType;

    // @todo: JQ-3488 change once the type is adopted, now, if not available,
    // it is an empty array if there is data, it is an object
    if (Array.isArray(frameTypeDefaults) || !frameTypeDefaults) {
      return null;
    }

    return frameTypeDefaults;
  };

  const getFramesForProductType1 = (productType: string) => {
    const frameGroup = getFrameGroupForProductType(productType);

    return frameGroup && Object.keys(frameGroup).length
      ? Object.keys(frameGroup)
      : null;
  };

  const getFrameTypeParamByFrameGroup = (productType: string, frameGroup: string | undefined | null) => {
    const frameParam = getFramesForProductType(productType) && frameGroup
      ? getFramesForProductType(productType)[frameGroup]
      : null;

    if (!frameParam) {
      return null;
    }
    const {
      matSize,
      matType,
    } = getFrameGroupDefaults(productType);

    const isFrameGroup = frameGroup && frameGroup !== 'without';

    const matTypeParam = isFrameGroup
      ? matType[frameGroup]
      : null;
    const matSizeParam = isFrameGroup
      ? matSize[frameGroup]
      : null;

    return {
      [UrlParams.FrameType]: frameParam,
      ...(matTypeParam && { [UrlParams.MatType]: matTypeParam }),
      ...(matSizeParam && { [UrlParams.MatSize]: matSizeParam }),
    };
  };

  return {
    fetchProductData,
    getAspectRatioDefaultForProductType,
    getAspectRatiosForProductType,
    getColorsForProductType,
    getFrameGroupDefaults,
    getFrameGroupForProductType,
    getFramesForProductType,
    getFramesForProductType1,
    getFrameTypeParamByFrameGroup,
    getMaterialsForProductType,
    getProductData,
    getProductsToSwitch,
    getProductTypeCategoryForProductType,
    getSizeDefaultForProductType,
    getSizesForProductType,
    getVariantsForProductType,
  };
};

export { useProductData };
