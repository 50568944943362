enum UrlParams {
  AddToCart = 'addToCart',
  Color = 'merchColor',
  FrameType = 'frameType',
  Material = 'merchMaterial',
  MaterialType = 'materialType',
  MatSize = 'matSize', // mat stands for passepartout, not for material!
  MatType = 'matType', // mat stands for passepartout, not for material!
  MerchSize = 'merchSize',
  MerchType = 'merchType',
  Preview = 'preview',
  ProductOptionsConfigurator = 'productOptionsAndSelection',
  Variant = 'merchVariant',
  WallartSize = 'formatType'
}

export { UrlParams };
